<template>
  <div class="wrapper">
    <h2 class="title">
      <p>404</p>
      <p>页面不存在</p>
    </h2>
    <div style="margin-bottom: 0.2rem">
      <van-button block @click="goBack">返回</van-button>
    </div>
    <div>
      <van-button type="primary" block @click="goHome">返回首页</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goHome() {
      this.$router.replace("/");
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
  padding: 0 30px;
}
.title {
  margin-top: 20%;
  margin-bottom: 1rem;
  text-align: center;
}
</style>